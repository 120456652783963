import { gql } from '@apollo/client';

export const SIGNUP_PATIENT = gql`
  mutation signUpPatient(
    $firstName: String!
    $lastName: String!
    $identifier: String
    $gender: String!
    $pronoun: String
    $genderIdentity: GenderIdentity
    $birthdate: String!
    $phone: String!
    $email: String!
    $labId: Int!
    $event: String
    $address: AddressInput!
    $referralSource: String
    $referralSourceDetails: String
    $password: String
    $googleToken: String
    $appleToken: String
    $preferredTimezone: String
    $sendSmsAppointmentNotification: Boolean
    $sendMarketingEmail: Boolean
  ) {
    signUpPatient(
      firstName: $firstName
      lastName: $lastName
      identifier: $identifier
      gender: $gender
      pronoun: $pronoun
      genderIdentity: $genderIdentity
      birthday: $birthdate
      phone: $phone
      email: $email
      labId: $labId
      event: $event
      address: $address
      referralSource: $referralSource
      referralSourceDetails: $referralSourceDetails
      password: $password
      googleToken: $googleToken
      appleToken: $appleToken
      preferredTimezone: $preferredTimezone
      sendSmsAppointmentNotification: $sendSmsAppointmentNotification
      sendMarketingEmail: $sendMarketingEmail
    ) {
      patient {
        id
        firstName
        lastName
        createdAt
        identifier
        lab {
          id
          timeZone
          name
          vios
        }
        patientMembership {
          id
          membershipId
          employer
          employerPhone
          createdAt
          membership {
            name
            id
            documentUrl
            description
            employerId
            hidePartnerClinics
          }
          employerName
        }
        gender
        birthday
        email
        phone
        preferredTimezone
        sendSmsAppointmentNotification
        sendMarketingEmail
      }
    }
  }
`;

export const VERIFY_PATIENT_EMAIL = gql`
  mutation verifyPatientEmail($email: String!, $verificationCode: String!) {
    verifyPatientEmail(email: $email, verificationCode: $verificationCode) {
      succeeded
      errorCode
    }
  }
`;

export const AUTHENTICATE_PATIENT = gql`
  mutation authenticatePatient($email: String!, $password: String!, $otp: String) {
    authenticatePatient(email: $email, password: $password, otp: $otp) {
      succeeded
      errorCode
      errorMetaData
      idToken
      accessToken
      refreshToken
    }
  }
`;

export const REQUEST_PASSWORD_CHANGE_CODE = gql`
  mutation requestPasswordChangeCode($email: String!) {
    requestPasswordChangeCode(email: $email) {
      succeeded
      errorCode
    }
  }
`;

export const CONFIRM_PASSWORD_CHANGE = gql`
  mutation confirmPasswordChange(
    $email: String!
    $code: String!
    $newPassword: String!
    $sendSmsAppointmentNotification: Boolean
    $sendMarketingEmail: Boolean
  ) {
    confirmPasswordChange(
      email: $email
      code: $code
      newPassword: $newPassword
      sendSmsAppointmentNotification: $sendSmsAppointmentNotification
      sendMarketingEmail: $sendMarketingEmail
    ) {
      succeeded
      errorCode
    }
  }
`;

export const RESEND_EMAIL_VERIFICATION_CODE = gql`
  mutation resendEmailVerificationCode($email: String!) {
    resendEmailVerificationCode(email: $email) {
      succeeded
      errorCode
    }
  }
`;

export const UPDATE_PATIENT_LAST_SIGN_IN = gql`
  mutation updatePatient {
    updatePatient {
      patient {
        id
      }
    }
  }
`;

export const INVALIDATE_PATIENT_AUTH_SESSION = gql`
  mutation invalidatePatientAuthSession {
    invalidatePatientAuthSession {
      succeeded
      errorCode
    }
  }
`;

export const CANCEL_APPOINTMENT_W_REASON = gql`
  mutation cancelAppointment($appointmentId: Int!, $reason: String!, $note: String) {
    cancelAppointment(appointmentId: $appointmentId, reason: $reason, note: $note) {
      appointment {
        id
        patientPackageId
        appointmentType {
          id
          virtual
          name
          description
          videoLink
          rateCents
          category {
            id
            name
          }
        }
        location {
          id
          name
          address
        }
        startTime
        endTime
        timeZone
      }
    }
  }
`;

export const RESCHEDULE_APPOINTMENT = gql`
  mutation rescheduleAppointment($appointmentId: Int!, $newTimeSlotId: Int!) {
    rescheduleAppointment(appointmentId: $appointmentId, newTimeSlotId: $newTimeSlotId) {
      appointment {
        id
        patientPackageId
        cancelable
        reschedulable
        appointmentType {
          id
          virtual
          name
          description
          videoLink
          rateCents
          category {
            id
            name
          }
        }
        location {
          id
          name
          address
        }
        startTime
        endTime
        timeZone
      }
    }
  }
`;

export const PURCHASE_PACKAGE = gql`
  mutation createPatientPackage(
    $packageId: Int!
    $stripeTokenId: String
    $discountCode: String
    $kbStripeCardStripeIdentifier: String
  ) {
    createPatientPackage(
      packageId: $packageId
      stripeTokenId: $stripeTokenId
      discountCode: $discountCode
      kbStripeCardStripeIdentifier: $kbStripeCardStripeIdentifier
    ) {
      patientPackage {
        id
        name
        description
        rateCents
        bookableAppointments: bookablePackageItemTypes {
          id
          appointmentTypeId
          name
          description
          virtual
          videoLink
          locations {
            id
            name
            address
          }
        }
        previouslyBookedAppointments {
          startTime
          endTime
          timeZone
          appointmentType {
            id
            virtual
            name
            description
            videoLink
          }
        }
        appointmentPackage: package {
          id
          name
          quantity
        }
      }
    }
  }
`;

export const INTAKE_SAVE_ANSWER = gql`
  mutation createAnswer($formId: Int!, $formElementId: Int!, $data: String, $dataArray: [String!]) {
    createAnswer(
      formId: $formId
      formElementId: $formElementId
      data: $data
      dataArray: $dataArray
    ) {
      answer {
        id
        data
        dataArray
        formElementId
        formElementTitle
      }
      percentageComplete
      errors
    }
  }
`;

export const INTAKE_COMPLETE_FORM = gql`
  mutation updateFormCompletion($formId: Int!, $completedAt: String!) {
    updateFormCompletion(formId: $formId, completedAt: $completedAt) {
      formCompletion {
        id
        completedAt
      }
      errors
    }
  }
`;

/**
 * Period tracking
 */
export const CREATE_PERIOD = gql`
  mutation createPeriod($periodDate: String!) {
    createPeriod(periodDate: $periodDate) {
      period {
        periodDate
        nextPeriodDate
        daysBetweenCycles
        id
      }
    }
  }
`;

export const UPDATE_PERIOD = gql`
  mutation updatePeriod($id: ID!, $periodDate: String!) {
    updatePeriod(id: $id, periodDate: $periodDate) {
      period {
        periodDate
        nextPeriodDate
        daysBetweenCycles
        id
      }
    }
  }
`;

export const UPDATE_CYCLE_LENGTH = gql`
  mutation updateCycleLength($daysBetweenCycles: Int!) {
    updateCycleLength(daysBetweenCycles: $daysBetweenCycles) {
      period {
        periodDate
        nextPeriodDate
        daysBetweenCycles
        id
      }
    }
  }
`;

export const RECOGNIZE_PATIENT_FROM_EPIC_SSO = gql`
  mutation recognizePatientFromEpicSso($epicIdtoken: String!, $epicAccessToken: String!) {
    recognizePatientFromEpicSso(epicIdtoken: $epicIdtoken, epicAccessToken: $epicAccessToken) {
      resultCode
      errors
      patient {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

// it is the same mutation as above, just different use case
export const CREATE_PATIENT_VIA_EPIC_SSO = gql`
  mutation recognizePatientFromEpicSso(
    $epicIdtoken: String!
    $epicAccessToken: String!
    $newPatientData: EpicNewPatient!
    $address: AddressInput!
  ) {
    recognizePatientFromEpicSso(
      epicIdtoken: $epicIdtoken
      epicAccessToken: $epicAccessToken
      newPatientData: $newPatientData
      address: $address
    ) {
      resultCode
      errors
      patient {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const RECOGNIZE_PATIENT_FROM_MEDTRONIC_SSO = gql`
  mutation recognizePatientFromMedtronicSso($medtronicIdtoken: String!) {
    recognizePatientFromMedtronicSso(medtronicIdtoken: $medtronicIdtoken) {
      resultCode
      errors
      patient {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

// it is the same mutation as above, just different use case
export const CREATE_PATIENT_VIA_MEDTRONIC_SSO = gql`
  mutation recognizePatientFromMedtronicSso(
    $medtronicIdtoken: String!
    $newPatientData: MedtronicNewPatient!
    $address: AddressInput!
  ) {
    recognizePatientFromMedtronicSso(
      medtronicIdtoken: $medtronicIdtoken
      newPatientData: $newPatientData
      address: $address
    ) {
      resultCode
      errors
      patient {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

export const REGISTER_PATIENT_TO_EVENT = gql`
  mutation registerToEvent($event: String!) {
    registerToEvent(event: $event) {
      event
    }
  }
`;

export const UPDATE_PATIENT_EMAIL = gql`
  mutation updatePatientEmail($newEmail: String!, $code: String, $accessToken: String) {
    updatePatientEmail(newEmail: $newEmail, code: $code, accessToken: $accessToken) {
      succeeded
      errorCode
    }
  }
`;

export const UPDATE_DECLARED_PARTNER = gql`
  mutation updatePatient($hasPartnersPatientDeclared: Boolean) {
    updatePatient(hasPartnersPatientDeclared: $hasPartnersPatientDeclared) {
      patient {
        id
      }
    }
  }
`;

export const UPDATE_DECLARED_INSURANCE = gql`
  mutation updatePatient($declaredInsurance: Boolean) {
    updatePatient(declaredInsurance: $declaredInsurance) {
      patient {
        id
      }
    }
  }
`;

export const CONFIRM_NEW_PATIENT_EMAIL = gql`
  mutation confirmNewEmail($requestId: String!) {
    confirmNewEmail(requestId: $requestId) {
      succeeded
      errorCode
    }
  }
`;

export const REQUEST_MFA_VALIDATION_CODE = gql`
  mutation requestMfaValidationCode($phone: String!) {
    requestMfaValidationCode(phone: $phone) {
      succeeded
      errorCode
    }
  }
`;

export const VALIDATE_MFA_CODE = gql`
  mutation validateMfaCode($phone: String!, $code: String!) {
    validateMfaCode(phone: $phone, code: $code) {
      succeeded
      errorCode
    }
  }
`;

export const DISABLE_MFA = gql`
  mutation disableMfa {
    disableMfa {
      succeeded
      errorCode
    }
  }
`;
