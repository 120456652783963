import { useMutation } from '@apollo/client';

import { CREDIT_CARDS_QUERY } from 'hooks/useCreditCards/useCreditCards.graphql';
import { BugTracker } from 'kb-shared/utilities/bugTracker';

import { showErrorToast } from '../../../../utilities/notificationUtils';
import { SET_DEFAULT_CARD, DELETE_CARD, ADD_CARD } from './CreditCard.graphql';
import {
  ERROR_MESSAGE_ADD_CARD,
  ERROR_MESSAGE_DELETE_CARD,
  ERROR_MESSAGE_SET_DEFAULT_CARD,
  CARD_DECLINED
} from './useCreditCardMutation.constants';

export const useCreditCardMutation = () => {
  const handleError = (error: Error, context: string) => {
    BugTracker.notify(context, error);
    showErrorToast(context + '. Please try again later.');
  };

  const handleActionRefusal = (message: string) => showErrorToast(message);

  const [setDefaultCard] = useMutation(SET_DEFAULT_CARD, {
    refetchQueries: [{ query: CREDIT_CARDS_QUERY }],
    onError: error => handleError(error, ERROR_MESSAGE_SET_DEFAULT_CARD)
  });

  const [deleteCard] = useMutation(DELETE_CARD, {
    refetchQueries: [{ query: CREDIT_CARDS_QUERY }],
    onError: error => handleError(error, ERROR_MESSAGE_DELETE_CARD)
  });

  const [addCard, { loading: addCardLoading }] = useMutation(ADD_CARD, {
    refetchQueries: [{ query: CREDIT_CARDS_QUERY }],
    onError: error => {
      if (error?.message.includes('Your card was declined.')) {
        handleActionRefusal(CARD_DECLINED);
      } else {
        handleError(error, ERROR_MESSAGE_ADD_CARD);
      }
    }
  });

  return { setDefaultCard, deleteCard, addCard, addCardLoading };
};
