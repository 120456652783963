import configure from './config';
import client from './graphql/client';
import {
  RESCHEDULE_APPOINTMENT,
  CANCEL_APPOINTMENT_W_REASON,
  PURCHASE_PACKAGE,
  INTAKE_SAVE_ANSWER,
  INTAKE_COMPLETE_FORM,
  CREATE_PERIOD,
  UPDATE_PERIOD,
  UPDATE_CYCLE_LENGTH,
  RECOGNIZE_PATIENT_FROM_EPIC_SSO,
  CREATE_PATIENT_VIA_EPIC_SSO,
  RECOGNIZE_PATIENT_FROM_MEDTRONIC_SSO,
  CREATE_PATIENT_VIA_MEDTRONIC_SSO
} from './graphql/mutations';
import { checkPromoCode } from './graphql/promo_codes';
import {
  PATIENT,
  PATIENT_NAME,
  PATIENT_LAB,
  PATIENT_APPOINTMENTS,
  CLINICS,
  VALIDATE_APPOINTMENT_DISCOUNT,
  VALIDATE_PACKAGE_DISCOUNT,
  INTAKE_FORM,
  USER_PERIOD,
  PACKAGES,
  LABS
  // ⚠️ Queries and mutation should rarely be shared.
  // Instead, co-locate queries inside the same file as the component in which they are used.
  // See https://www.apollographql.com/docs/resources/graphql-glossary/#query-colocation
} from './graphql/queries';
import theme from './theme/web';
import { getIdToken } from './utilities/api';
import {
  isFertility,
  isGYNAppointment,
  isAssessment,
  isVirtualConsultation,
  isAMH,
  AppointmentTypeIds,
  isInitialCycleVisit,
  isIUIProcedure,
  isRetrieval,
  isFollowUp,
  isPregnancyTest,
  isEmbryoTransfer,
  isCycleVisit,
  cycleVisitId,
  initialCycleVisitId,
  isPulseUltrasoundBloodwork,
  isRequiredToBeInTheSameState,
  shouldDisplayProvider
} from './utilities/appointment.helper';
import { sanitizeUsername } from './utilities/auth.helper';
import { setClearPwLocalStorage } from './utilities/clear_pw_storage';
import {
  setConsent,
  setConsentSearch,
  getConsent,
  getConsentSearch
} from './utilities/consent.user';
import { COUNTRIES_OPTIONS, US_COUNTRY_OPTION } from './utilities/countries';
import { calculateBirthProbability, calculateEggCountRange } from './utilities/eggCount';
import {
  GENDER_INPUT_DEFAULTS,
  PRONOUN_INPUT_DEFAULTS,
  GENDER_IDENTITY_DEFAULTS
} from './utilities/input_defaults.helper';
import {
  normalizeIntakeForm,
  intakeFormId,
  mapIntakeFormAnswers
} from './utilities/intake_form.helper';
import { decodeJwtPayload } from './utilities/jwt.helper';
import KBContacts, { getLabInfo } from './utilities/kindbody_contacts';
import { labs } from './utilities/lab.helper';
import { ROLES_TO_PATIENT_LANGUAGE, nameToInitials } from './utilities/messaging';
// GraphQL
//NOTE: We only export the "web" theme which has styled components
// Native cannot consume styled-components/css
// Native imports directly from src/theme which is ok
import {
  normalizeAppointments,
  mappedTimeZone,
  normalizeDays,
  inFuture,
  inFutureWithDelay,
  inPast,
  isToday,
  momentInTimeZone
} from './utilities/momentHelpers';
import {
  getAMHDescription,
  getAFCDescription,
  getNormalizedFollicleData,
  getSizeRange,
  getEstradiolLevel
} from './utilities/results';
import states from './utilities/states';
import {
  isEmailValid,
  isEmailCodeValid,
  PASSWORD_ERRORS,
  getPasswordErrors,
  isPasswordValid,
  isPasswordMatch,
  isInputValid,
  isPhoneNumberValid,
  isLabValid,
  isDOBValid,
  isDateValid
} from './utilities/validation';

// Configuration

const graphql = {
  client: client,
  mutate: {
    RESCHEDULE_APPOINTMENT,
    CANCEL_APPOINTMENT_W_REASON,
    PURCHASE_PACKAGE,
    INTAKE_SAVE_ANSWER,
    INTAKE_COMPLETE_FORM,
    CREATE_PERIOD,
    UPDATE_PERIOD,
    UPDATE_CYCLE_LENGTH,
    RECOGNIZE_PATIENT_FROM_EPIC_SSO,
    CREATE_PATIENT_VIA_EPIC_SSO,
    RECOGNIZE_PATIENT_FROM_MEDTRONIC_SSO,
    CREATE_PATIENT_VIA_MEDTRONIC_SSO
  },
  query: {
    PATIENT,
    PATIENT_NAME,
    PATIENT_LAB,
    CLINICS,
    PATIENT_APPOINTMENTS,
    VALIDATE_APPOINTMENT_DISCOUNT,
    VALIDATE_PACKAGE_DISCOUNT,
    INTAKE_FORM,
    USER_PERIOD,
    PACKAGES,
    LABS
  },
  requests: {
    checkPromoCode
  }
};

// TODO remove the use of themes, there is only 1 theme in this object
const themes = theme;

const utils = {
  ROLES_TO_PATIENT_LANGUAGE,
  GENDER_INPUT_DEFAULTS,
  PRONOUN_INPUT_DEFAULTS,
  GENDER_IDENTITY_DEFAULTS,
  PASSWORD_ERRORS,
  COUNTRIES_OPTIONS,
  US_COUNTRY_OPTION,
  nameToInitials,
  setClearPwLocalStorage,
  normalizeIntakeForm,
  intakeFormId,
  mapIntakeFormAnswers,
  sanitizeUsername,
  KBContacts,
  getLabInfo,
  normalizeAppointments,
  mappedTimeZone,
  momentInTimeZone,
  normalizeDays,
  inFuture,
  inFutureWithDelay,
  inPast,
  isToday,
  setConsent,
  setConsentSearch,
  getConsent,
  getConsentSearch,
  isFertility,
  isGYNAppointment,
  isAssessment,
  isVirtualConsultation,
  isPulseUltrasoundBloodwork,
  isInitialCycleVisit,
  isIUIProcedure,
  isRetrieval,
  isFollowUp,
  isPregnancyTest,
  isEmbryoTransfer,
  shouldDisplayProvider,
  isCycleVisit,
  cycleVisitId,
  initialCycleVisitId,
  isAMH,
  isRequiredToBeInTheSameState,
  AppointmentTypeIds,
  getIdToken,
  states,
  isEmailValid,
  isEmailCodeValid,
  getPasswordErrors,
  isPasswordValid,
  isPasswordMatch,
  isInputValid,
  isPhoneNumberValid,
  isLabValid,
  isDOBValid,
  isDateValid,
  getAMHDescription,
  getAFCDescription,
  getNormalizedFollicleData,
  getSizeRange,
  getEstradiolLevel,
  calculateBirthProbability,
  calculateEggCountRange,
  labs,
  decodeJwtPayload
};

export { configure, graphql, themes, theme, utils };

export * from './utilities/post_embryo_transfer';
export * from './utilities/post_op';
export * from './utilities/pre_op';
export * from './utilities/partner';

export * from './types';
export * from './guards';
export * from './graphql/types';

export * from './utilities/myJourney';
export * from './utilities/results';
