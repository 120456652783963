import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Loader } from 'components/Loader/Loader';
import { Tabs } from 'components/v2/Tabs/Tabs';
import { useQueryParams } from 'hooks/useQueryParams';
import { themes } from 'kb-shared';
import { ResponsiveContainer } from 'screens/styled_common';
import { AbsoluteCentered } from 'styled';
import { analytics } from 'utilities/analytics';
import { pageUrl } from 'utilities/pageUrl';
import { useBreakpoints } from 'utilities/useBreakpoints';

import { CyclePayments } from './components/CyclePayments/CyclePayments';
import { InvoicePayModal } from './components/InvoicePayModal/InvoicePayModal';
import { InvoicesPageHeader } from './components/InvoicesPageHeader/InvoicesPageHeader';
import { PageableInvoices } from './components/PagableInvoices/PagableInvoices';
import { PayCycleModal } from './components/PayCycleModal/PayCycleModal';
import { UnappliedPayments } from './components/UnappliedPayments/UnappliedPayments';
import useInvoices from './hooks/use-invoices';
import useTotalBalance from './hooks/use-total-balance';
import useUnappliedPayments from './hooks/use-unapplied-payments';
import Invoice from './types/invoice';

export function Invoices() {
  const [invoiceToPay, setInvoiceToPay] = useState<Invoice | undefined>(undefined);
  const [paymentWithoutInvoiceModalOpen, setPaymentWithoutInvoiceModalOpen] = useState(false);
  const history = useHistory();
  const queryString = useQueryParams();
  const { isMobile } = useBreakpoints();
  const { totalBalance, loading: totalBalanceLoading } = useTotalBalance();
  const PAGE_SIZE = 10;
  const tabUrlKey = 'tab';
  const selectedTabId = queryString.get(tabUrlKey);

  const {
    loading: invoicesLoading,
    error: invoicesError,
    invoices,
    currentPage,
    onNextPage,
    onPreviousPage,
    refetch: refetchInvoices
  } = useInvoices(PAGE_SIZE, totalBalanceLoading);

  const {
    unappliedPayments,
    error: unappliedPaymentsError,
    loading: unappliedPaymentsLoading,
    refetch: refetchUnappliedPayments
  } = useUnappliedPayments(totalBalanceLoading);

  const onViewInvoice = (invoice: Invoice) => {
    const invoicesPage = pageUrl.invoices({
      invoiceIdentifier: getInvoiceIdentifier(invoice)
    });
    history.push(invoicesPage);
  };

  useEffect(() => {
    analytics.page(analytics.PAGES.INVOICES);
  }, []);

  const tabs = [
    {
      id: 'invoices',
      label: 'Invoices',
      content: (
        <PageableInvoices
          invoices={invoices}
          currentPage={currentPage}
          onInvoiceSelected={invoice => setInvoiceToPay(invoice)}
          onNextPage={onNextPage}
          onPreviousPage={onPreviousPage}
          onViewInvoice={onViewInvoice}
          error={invoicesError}
        />
      )
    },
    {
      id: 'payments',
      label: 'Payments',
      content: <CyclePayments onMakeCyclePayment={() => setPaymentWithoutInvoiceModalOpen(true)} />
    },
    {
      id: 'unapplied-payments',
      label: 'Unapplied Payments',
      content: (
        <UnappliedPayments unappliedPayments={unappliedPayments} error={unappliedPaymentsError} />
      )
    }
  ];

  if (totalBalanceLoading || invoicesLoading || unappliedPaymentsLoading) {
    return (
      <AbsoluteCentered>
        <Loader type="spin" color={themes.colors.yellow.primary} height={150} width={150} />
      </AbsoluteCentered>
    );
  }

  const refetchData = () => {
    refetchUnappliedPayments();
    refetchInvoices();
  };

  const closeInvoicePayModal = () => setInvoiceToPay(undefined);
  const onInvoicePaid = () => {
    closeInvoicePayModal();
    refetchData();
  };

  const closeCycleModal = () => setPaymentWithoutInvoiceModalOpen(false);
  const onCyclePaid = () => {
    closeCycleModal();
    refetchData();
  };

  const isTabSelectedOnSmallScreen = isMobile && !!selectedTabId;

  return (
    <ResponsiveContainer paddedContent>
      {!isTabSelectedOnSmallScreen && <InvoicesPageHeader totalBalance={totalBalance} />}

      <Tabs tabs={tabs} tabUrlKey={tabUrlKey} noMargin />

      {invoiceToPay && (
        <InvoicePayModal
          invoice={invoiceToPay}
          open={true}
          onClose={closeInvoicePayModal}
          onPaid={onInvoicePaid}
        />
      )}
      {paymentWithoutInvoiceModalOpen && (
        <PayCycleModal
          open={paymentWithoutInvoiceModalOpen}
          onClose={closeCycleModal}
          onPaid={onCyclePaid}
        />
      )}
    </ResponsiveContainer>
  );
}

const getInvoiceIdentifier = (invoice: Invoice) => invoice.azEncounterIdentifier || invoice.id;
